<template>
    <OColumn
        colId="timeline_colid" 
        headerName="Timeline" 
        width="850px" 
        editable
        :groupAggregateFn="(data) => data">
        <template #contextmenuTop="{ row, close }">
            <button class="dropdown-item"
                @click="() => { scrollTimelineTo(new Date(row.PlannedStart), 'center', 1.05); close() }">
                <i class="bi bi-arrow-left-right me-1"></i>
                {{ $t('Scroll To This Activity') }}
            </button>
            <button class="dropdown-item"
                @click="() => { zoomTimelineTo(new Date(row.PlannedStart), new Date(row.PlannedFinish), 1.05); close() }">
                <i class="bi bi-zoom-in me-1"></i>
                {{ $t('Zoom To This Activity') }}
            </button>
            <div class="dropdown-divider"></div>
        </template>
        <template #headertext>
            <CalendarHeader
                :width="timelineWidth"
                :from="timelineLeftDate"
                :to="timelineRightDate"
                :outerFrom="minBarDate"
                :outerTo="maxBarDate"
                :hasReachedMax="hasReachedMax"
                :barTypes="props.barTypes"
                :isPinned="pinnedVal"
                @toggle-pinned="handleTogglePinned"
                @toggle-active="handleToggleActive"
                @enter-pan-mode="enterPanMode($event)"
                @zoom-timeline="(direction) => zoomTimeline((direction == 'out' ? 1.25 : 0.75), 0.5)"
                @scroll-timeline-to="(fromDate) => scrollTimelineTo(fromDate, 'center', 1)"
                @set-zoom="(timeUnit, pIsPreview) => setZoom(timeUnit, pIsPreview)"
                @reset-zoom-preview="resetZoomPreview" />
        </template>
        <template #filter>
            <CalendarHeader
                :width="timelineWidth"
                :from="timelineLeftDate"
                :to="timelineRightDate"
                :outerFrom="minBarDate"
                :outerTo="maxBarDate"
                :showLower="true" />
        </template>
        <template #default="{ row }">
            <TimelineRow
                :bars="props.bars[row.PrimKey]"
                :barTypes="changedState"
                :timelineWidth="timelineWidth"
                :timelineLeftDate="timelineLeftDate"
                :timelineRightDate="timelineRightDate"
                :timelineCursor="timelineCursor"
                :disableResize="props.disableResize"
                @pan-timeline="event => mouseDownTimeline(event)"
                @stop-panning="event => mouseUpTimeline(event)"
                @left-arrow-click="scrollTimelineTo(new Date(row.PlannedStart), 'left', 1.05)"
                @right-arrow-click="scrollTimelineTo(new Date(row.PlannedFinish), 'right', 1.05)"
                @mousewheel="scrollHandler"
                @cursor-changed="updateCursor"
                @dates-changed="updateBar"
                @isDragging="updateRowDragging" />
        </template>
        <template #editor="{ row }" v-if="props.overlayButtons">
            <div style="background-color: cornsilk; z-index: 200;" @click="showOverlay($event.pageX, $event.pageY)">
                <TimelineRow
                    :bars="props.bars[row.PrimKey]"
                    :barTypes="props.barTypes"
                    :timelineWidth="timelineWidth"
                    :timelineLeftDate="timelineLeftDate"
                    :timelineRightDate="timelineRightDate"
                    :timelineCursor="timelineCursor"
                    @pan-timeline="event => mouseDownTimeline(event)"
                    @stop-panning="event => mouseUpTimeline(event)"
                    @left-arrow-click="scrollTimelineTo(new Date(row.PlannedStart), 'left', 1.05)"
                    @right-arrow-click="scrollTimelineTo(new Date(row.PlannedFinish), 'right', 1.05)"
                    @mousewheel="scrollHandler" />
                <TimelineOverlayEditor 
                    v-if="showEditor"
                    :row="row" 
                    :coorX="overlayPosition.x" 
                    :coorY="overlayPosition.y" 
                    :buttons="props.overlayButtons" 
                    @open-component="handleOpenComponent" />
            </div>
        </template>
    </OColumn>
</template>

<script setup lang="ts">
import CalendarHeader from 'o365.vue.components.DataGrid.Header.CalendarHeader.vue';
import TimelineRow from 'o365.vue.components.DataGrid.TimelineRow.vue';
import TimelineOverlayEditor from 'o365.vue.components.DataGrid.TimelineOverlayEditor.vue';
import type DataGridControl from 'o365.controls.DataGrid.ts';
import { dataGridRefKey } from 'o365.modules.vue.injectionKeys.js';
import { Ref, watch, ref, onMounted, onUnmounted, reactive  } from 'vue';
import { defineProps } from 'vue';
import $t from 'o365.modules.translate.ts';
import { computed, inject } from 'vue';
import useEventListener from 'o365.vue.composables.EventListener.ts'

const dataGridRef = inject<Ref<DataGridControl>>(dataGridRefKey);
const dataSource = dataGridRef.value.dataGridControl.dataObject;

const emit = defineEmits(['open-component', 'update-bars', 'timeline-dates'])

const parentGrid = dataGridRef;

let isPreviewing = false;
let currentLeftDate = null;
let currentRightDate = null;
let timelineMouseDown = ref(false);
let timelineMouseDownScreenX = null;
let timelineMouseDownScreenY = null;
const overlayPosition = reactive({ x: 0, y: 0 });
const showEditor = ref(false);
let isPanning = ref(false);
let isDragging = ref(false);
const hasReachedMax = ref(false);
const timelineLeftDate = ref();
const timelineRightDate = ref();
const minBarDate = ref();
const maxBarDate = ref();
const timelineCursor = ref('all-scroll');

const colId = 'timeline_colid';

const props = defineProps({
    bars: Object,
    barTypes: Map<String, Object>,
    timelineViewFrom: Date,
    timelineViewTo: Date,
    hide: Boolean,
    overlayButtons: Map<String, Object>,
    disableResize: Boolean
});

const changedState = ref(props.barTypes);

const handleToggleActive = (bar) => {
    console.log('Received toggle-active event for bar:', bar);
    const updatedBars = bar
    changedState.value = updatedBars
}

const pinnedVal = computed(() => dataGridRef.value.dataGridControl.dataColumns.getColumn('timeline_colid')?.pinned)

const handleTogglePinned = (pinValue) => {
    const columnId = dataGridRef.value.dataGridControl.dataColumns.getColumn('timeline_colid')
    console.log('Received toggle-pinned event:', pinValue);
    dataGridRef.value.dataGridControl.header.setColumnPin(columnId, null)
    
}

function updateCursor(cursor) {
    timelineCursor.value = cursor
}

function updateRowDragging(isDraggingRow) {
    console.log(isDraggingRow)
    isDragging.value = isDraggingRow
}

function updateBar(bar) {
    const currentActivity = dataSource.current
    
    currentActivity.PlannedStart = bar.from
    currentActivity.PlannedFinish = bar.to

    if(!isDragging.value) {
        dataSource.save()
    }
    
}

dataSource.on("DataLoaded", () => {
    minBarDate.value = new Date(Math.min(...dataSource.getData().map(x => new Date(x.PlannedStart))));

    maxBarDate.value = new Date(Math.max(...dataSource.getData().map(x => new Date(x.PlannedFinish))));
});

async function setTimelineViewDates() {
    if (!timelineLeftDate.value) {
        timelineLeftDate.value = props.timelineViewFrom ? new Date(props.timelineViewFrom) : getDefaultStart();
    }
    if (!timelineRightDate.value) {
        timelineRightDate.value = props.timelineViewTo ? new Date(props.timelineViewTo) : getDefaultFinish();
    }
}

watch([timelineLeftDate, timelineRightDate], () => {
    emitTimelineDatesChanged();
});

const emitTimelineDatesChanged = () => {
    emit('timeline-dates', { 
        leftDate: timelineLeftDate.value, 
        rightDate: timelineRightDate.value 
    });
};

function handleOpenComponent(component) {
    console.log('Received open-component event:', component);
    emit('open-component', component);
}

const updateHideValue = () => {
    const timelineCol = parentGrid.value?.dataColumns.columns.find(c => c.colId == 'timeline_colid');
    timelineCol.hide = props.hide;
}

function getDefaultStart() {
    const currentDate = new Date();
    const oneWeekAgo = new Date(currentDate);
    oneWeekAgo.setDate(currentDate.getDate() - 7);

    return oneWeekAgo;
}

function getDefaultFinish() {
    const currentDate = new Date();
    const oneMonth = new Date(currentDate);
    oneMonth.setDate(currentDate.getDate() + 30);

    return oneMonth;
}

const showOverlay = (x, y) => {
    overlayPosition.x = x;
    overlayPosition.y = y;
    showEditor.value = true;
};

const timelineWidth = computed(() => {
    return parentGrid.value?.dataColumns ? parentGrid.value?.dataColumns.columns.find(c => c.colId == 'timeline_colid').width : 0;
});

function scrollTimeline(pScroll) {
    const timeFrame = timelineRightDate.value.getTime() - timelineLeftDate.value.getTime();
    const shift = timeFrame * pScroll;

    timelineLeftDate.value = new Date(timelineLeftDate.value.getTime() + shift);
    timelineRightDate.value = new Date(timelineRightDate.value.getTime() + shift);
}

function zoomTimeline(pZoom, pMouseLoc) {
    const timeFrame = timelineRightDate.value.getTime() - timelineLeftDate.value.getTime();
    const leftDiff = pMouseLoc * timeFrame;
    const rightDiff = (1 - pMouseLoc) * timeFrame;
    let newLeftDate = new Date(timelineLeftDate.value.getTime() + leftDiff - leftDiff * pZoom);
    let newRightDate = new Date(timelineRightDate.value.getTime() - rightDiff + rightDiff * pZoom);
    
    const minZoomValue = 3000000; // Limits zooming in to 15 minutes
    const maxZoomValue = 600000000000; // Limits zooming out to a decade

     if (newRightDate.getTime() - newLeftDate.getTime() < minZoomValue) {
        const diff = minZoomValue - (newRightDate.getTime() - newLeftDate.getTime());
        newLeftDate = new Date(newLeftDate.getTime() - diff / 2);
        newRightDate = new Date(newRightDate.getTime() + diff / 2);
    }

    if (newRightDate.getTime() - newLeftDate.getTime() > maxZoomValue) {
        const diff = newRightDate.getTime() - newLeftDate.getTime() - maxZoomValue;
        newLeftDate = new Date(newLeftDate.getTime() + diff / 2);
        newRightDate = new Date(newRightDate.getTime() - diff / 2);
    }

    if (newRightDate.getTime() - newLeftDate.getTime() == maxZoomValue || newRightDate.getTime() - newLeftDate.getTime() == minZoomValue) {
        hasReachedMax.value = true;
        console.log('max reached');
    } else {
        hasReachedMax.value = false;
    }

    
    timelineLeftDate.value = newLeftDate;
    timelineRightDate.value = newRightDate;
};

function scrollTimelineTo(pFrom, pLocation, pOffsetFactor) {
    const msDiff = timelineRightDate.value - timelineLeftDate.value;
    const msOffset = (msDiff * pOffsetFactor - msDiff) / 2;
    const msLocationAdjustment = (pLocation == 'left' ? 0 + msOffset : pLocation == 'right' ? msDiff - msOffset : pLocation == 'center' ? msDiff / 2 : 0 + msOffset);
    timelineLeftDate.value = new Date(pFrom.getTime() - msLocationAdjustment)
    timelineRightDate.value = new Date(timelineLeftDate.value.getTime() + msDiff);
};

function zoomTimelineTo(pFrom, pTo, pOffsetFactor) {
    const msDiff = pTo - pFrom;
    const msOffset = (msDiff * pOffsetFactor - msDiff) / 2;

    timelineLeftDate.value = new Date(pFrom.getTime() - msOffset);
    timelineRightDate.value = new Date(pTo.getTime() + msOffset);
}

function setZoom(pTimeUnit, pIsPreview) {
    if (pIsPreview) {
        isPreviewing = true;
        currentLeftDate = timelineLeftDate.value;
        currentRightDate = timelineRightDate.value;
    } else {
        isPreviewing = false;
        return;
    }

    const centerDate = new Date(timelineLeftDate.value.getTime() + (timelineRightDate.value.getTime() - timelineLeftDate.value.getTime()) / 2);

    switch (pTimeUnit) {
        case 'year':
            timelineLeftDate.value = new Date(new Date(centerDate).setMonth(centerDate.getMonth() - 6));
            timelineRightDate.value = new Date(new Date(centerDate).setMonth(centerDate.getMonth() + 6));
            return;
        case 'month':
            timelineLeftDate.value = new Date(new Date(centerDate).setDate(centerDate.getDate() - 15));
            timelineRightDate.value = new Date(new Date(centerDate).setDate(centerDate.getDate() + 15));
            return;
        case 'week':
            let leftDate = new Date(new Date(centerDate).setDate(centerDate.getDate() - 3));
            leftDate.setHours(leftDate.getHours() - 12);
            let rightDate = new Date(new Date(centerDate).setDate(centerDate.getDate() + 3));
            rightDate.setHours(rightDate.getHours() + 12);
            timelineLeftDate.value = leftDate;
            timelineRightDate.value = rightDate;
            return;
        case 'day':
            timelineLeftDate.value = new Date(new Date(centerDate).setHours(centerDate.getHours() - 12));
            timelineRightDate.value = new Date(new Date(centerDate).setHours(centerDate.getHours() + 12));
            return;
        default:
            return;
    }
}

function resetZoomPreview() {
    if (isPreviewing) {
        timelineLeftDate.value = currentLeftDate ?? timelineLeftDate.value;
        timelineRightDate.value = currentRightDate ?? timelineRightDate.value;
    }
}

useEventListener(window, 'mousemove', (pEvent) => {
    if (isPanning.value) {
        mouseMoveHandler(pEvent);
    }
}, { passive: true });


function mouseMoveHandler(pEvent) {
    if (isPanning.value) {
        let screenXDiff = pEvent.movementX * 1;
        let screenYDiff = pEvent.movementY * 1;
        let scrollX = true;
        let scrollY = true;

        if (pEvent.screenX <= window.screen.availLeft + 10) {
            screenXDiff -= Math.abs(pEvent.movementY) * 1;
            scrollY = false;
        } else if (pEvent.screenX >= window.screen.availWidth - 10) {
            screenXDiff += Math.abs(pEvent.movementY) * 1;
            scrollY = false;
        } else if (pEvent.screenY <= window.screen.availTop + 10) {
            screenYDiff -= Math.abs(pEvent.movementX) * 1;
            scrollX = false;
        } else if (pEvent.screenY >= window.screen.availHeight - 10) {
            screenYDiff += Math.abs(pEvent.movementX) * 1;
            scrollX = false;
        }

        if (scrollX) {
            const offsetXProportion = screenXDiff / timelineWidth.value;
            const timeFrame = timelineRightDate.value.getTime() - timelineLeftDate.value.getTime();
            const timeDiff = timeFrame * offsetXProportion;
            timelineLeftDate.value = new Date(timelineLeftDate.value.getTime() - timeDiff);
            timelineRightDate.value = new Date(timelineRightDate.value.getTime() - timeDiff);
            timelineMouseDownScreenX = pEvent.screenX;
        }

        if (scrollY) {
            parentGrid.value.setScrollPosition(parentGrid.value.getScrollPosition() - screenYDiff);
            timelineMouseDownScreenY = pEvent.screenY;
        }

    }
}

onmouseup = function (pEvent) {
    isPanning.value = false;
    timelineMouseDown.value = false;
};

function mouseDownTimeline(pEvent) {
    isPanning.value = true;
    timelineMouseDown.value = true;
    timelineMouseDownScreenX = pEvent.screenX;
    timelineMouseDownScreenY = pEvent.screenY;
}

function mouseUpTimeline(pEvent) {
    isPanning.value = false;
    timelineMouseDown.value = false;
}

function enterPanMode(pEvent) {
    timelineMouseDown.value = true
    isPanning.value = true;
    timelineMouseDownScreenX = pEvent.screenX;
    timelineMouseDownScreenY = pEvent.screenY;
}

function scrollHandler(pEvent) {
    if (pEvent.ctrlKey && pEvent.shiftKey && pEvent.target.closest('[o365-field="timeline_colid"]')) {
        if (pEvent.deltaY > 0) {
            zoomTimeline(1.25, pEvent.offsetX / timelineWidth.value);
        } else {
            zoomTimeline(0.75, pEvent.offsetX / timelineWidth.value);
        }
    } else if (pEvent.ctrlKey && pEvent.target.closest('[o365-field="timeline_colid"]')) {
        if (pEvent.deltaY > 0) {
            zoomTimeline(1.25, 0.5);
        } else {
            zoomTimeline(0.75, 0.5);
        }
    } else if (pEvent.shiftKey && pEvent.target.closest('[o365-field="timeline_colid"]')) {
        if (pEvent.deltaY > 0) {
            scrollTimeline(0.1);
        } else {
            scrollTimeline(-0.1);
        }
    }

    if (pEvent.ctrlKey || pEvent.shiftKey && pEvent.target.closest('[o365-field="timeline_colid"]')) {
        pEvent.preventDefault();
        return false;
    }
}

watch(() => [props.timelineViewFrom, props.timelineViewTo], setTimelineViewDates);
watch(() => [props.hide], updateHideValue)

onMounted(() => {
    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', onmouseup);
});

onUnmounted(() => {
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', onmouseup);
});

defineExpose({ colId });

</script>

<style>
.o365-body-cell[o365-field=timeline_colid] {
  padding: 0 !important;
  margin: 0 !important;
}
</style>